module.exports = {
  //Demo
  baseUrl: "https://nilwireapi.wealwin.com",

  single_contract: "0x93310d33c44e5BE5bF208B89cA00096613f887cF",

  multiple_contract: "0x5D500Ee1a10AF9310560F75a66Cf885C96089A43",

  ownerAddr: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",

  network: "5",

  frontUrl: "https://nilwirenft-frontend.pages.dev/",

  API_URL: "https://nilwireapi.wealwin.com"

  //--------------------LIVE-----------------------

  // baseUrl: "https://api.satxswap.com",

  // single_contract: "0xc0eCb515891f21628F8e4B7696241B7A59A3CEE7",

  // multiple_contract: "0x28D9E10E464ed5AB1F4fb28e1B2B909bC928896d",

  // ownerAddr: "0x0874D799dF6319a281970674cBCB7B30E4C63e17",

  // network: "1",

  // frontUrl: "https://controls.satxswap.com/",

  // API_URL: "https://api.satxswap.com",
};
